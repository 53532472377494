@import '~antd/dist/antd.css';

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 3em;
}

.App-content {
  flex: 1;
}

.ant-message {
  bottom: 120px;
  top: initial;
}
